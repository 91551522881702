module.exports = [{
  plugin: require('/Users/daunnc/subversions/git/github/pomadchin/my-deck-3/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
  options: {
    "plugins": [],
    "gatsbyRemarkPlugins": ["gatsby-remark-import-code"],
    "remarkPlugins": [null, null]
  }
}, {
  plugin: require('/Users/daunnc/subversions/git/github/pomadchin/my-deck-3/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/Users/daunnc/subversions/git/github/pomadchin/my-deck-3/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/Users/daunnc/subversions/git/github/pomadchin/my-deck-3/node_modules/gatsby-theme-mdx-deck/gatsby-browser.js'),
  options: {
    "plugins": [],
    "cli": true,
    "contentPath": "/Users/daunnc/subversions/git/github/pomadchin/my-deck-3/deck.mdx"
  }
}];