import { CodeSurfer, CodeSurferColumns, Step } from 'code-surfer';
import { github, vsDark, shadesOfPurple, duotoneLight, nightOwl } from '@code-surfer/themes';
import { Image } from 'mdx-deck';
import Prism from 'prismjs';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-scala';
import React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  github: github,
  vsDark: vsDark,
  shadesOfPurple: shadesOfPurple,
  duotoneLight: duotoneLight,
  nightOwl: nightOwl,
  Image: Image,
  Prism: Prism,
  React: React
};